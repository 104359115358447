(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobxReact"), require("ReactRouterDOM"), require("antd"), require("moment"));
	else if(typeof define === 'function' && define.amd)
		define("privateNetDetail", ["React", "mobxReact", "ReactRouterDOM", "antd", "moment"], factory);
	else if(typeof exports === 'object')
		exports["privateNetDetail"] = factory(require("React"), require("mobxReact"), require("ReactRouterDOM"), require("antd"), require("moment"));
	else
		root["privateNetDetail"] = factory(root["React"], root["mobxReact"], root["ReactRouterDOM"], root["antd"], root["moment"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__) {
return 