import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import PrivateNetHeader from './component/privateNetHeader';
// 专网套件告警详情

import './index.less';

const Loading = Loader.Loading;
const NoData = Loader.loadBaseComponent('NoData');
const HorizontalScrollLayout = Loader.loadBaseComponent('HorizontalScrollLayout');
const ImageMovieMap = Loader.loadBusinessComponent('ImageMovieMap');
const KeyPonitRollingCard = Loader.loadBusinessComponent('Card', 'KeyPonitRollingCard');
const PageDetails = Loader.loadBusinessComponent('PageDetails');

@withRouter
@Decorator.withEntryLog()
@Decorator.businessProvider('tab', 'user', 'device')
@observer
class PrivateNetDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: {},
      oldData: undefined,
      operationDetail: '',
      alarmInfoList: [],
      detailList: [], // 外部告警列表
      isRealAlarm: false, // 是否从警情处理跳转（记录日志需要）
      libType: '',
      searchData: {},
    };
    /*
		libType
		详情类型
			重点人员布控历史告警详情  1
			非法入侵告警详情         2
			魅影告警								3
			专网套件告警详情         4
	*/
  }

  componentWillMount() {
    let { history } = this.props;
    const { id } = Utils.queryFormat(history.location.search);
    window.LM_DB.get('parameter', id)
      .then(data => {
        this.setState({
          loading: true,
          detailList: data.list,
          oldData:data.list.filter(v => v.id === id)[0],
          libType: data.libType,
          searchData: data.searchData,
          isRealAlarm: data.isRealAlarm
        });
        this.getDetail(id).then(() => {
          this.getAlarmList();
        }).catch(() => {
          this.setState({ loading: false });
        });
    });
  }

  getDetail = id => {
    const { libType, isRealAlarm = false } = this.state;
    return Service.alarmResult.alarmResults({ id, libType, isRealAlarm }).then(res => {
      let data = res.data;
      this.setState({
        loading: false,
        data,
        operationDetail: data.operationDetail
      });
      return data
    });
  };

  //获取下方列表
  getAlarmList = (item=false) => {
    let data = item ? item : this.state.data;
    let option = { monitorPersonPictureId: data.infoId };
    Service.alarmResult.queryAlarmResults(option).then(res => {
      this.setState({
        alarmInfoList: res.data.list
      });
    });
  };

  // 更新地址栏url
  updatePage = (id) => {
    const { tab } = this.props;
    const { searchData, isRealAlarm, libType } = this.state;
    window.LM_DB.add('parameter', {
      id: id.toString(),
      libType,
      isRealAlarm,
      searchData
    }).then(() => {
      tab.goPage({
        moduleName: 'privateNetDetail',
        data: { id },
        action: 'replace',
        isUpdate: true
      })
    });
  }

  handleChangeList = id => {
    this.updatePage(id);
    this.getDetail(id).then(() => {
      this.getAlarmList();
    })
  };

  changeDetailView = id => {
    const { detailList } = this.state;
    this.updatePage(id);
    this.getDetail(id).then(item => {
      this.getAlarmList(item).then(() => {
        let data = detailList.find(v => (v.id === id))
        this.setState({
          oldData: data,
        });
      })
    });
  };

  renderItem = (item, index) => {
    const { data } = this.state;
    return (
      <KeyPonitRollingCard
        data={item}
        isActive={item.id == data.id}
        handleChangeList={this.handleChangeList}
      />
    );
  };

  renderContent() {
    let {
      data,
      libType,
      detailList = [],
      alarmInfoList,
      loading,
      oldData
    } = this.state;
    if (loading) {
      return null;
    }
    if (Object.keys(data).length === 0) {
      return <NoData />;
    }
    let dataIndex = detailList.findIndex(v =>
        oldData ? v.id === oldData.id : v.id === data.id
      ),
      preData = undefined,
      nextData = undefined;
    if (dataIndex > 0) {
      preData = detailList[dataIndex - 1];
      nextData = detailList[dataIndex + 1];
    }
    if (dataIndex === 0 && detailList.length > 1) {
      nextData = detailList[dataIndex + 1];
    }
    return (
      <>
        <PrivateNetHeader data={data} />
        <div className="detail_imm">
          {preData ? (
            <PageDetails
              pageType="pre"
              waterType="multiple"
              imgUrl={preData.faceUrl}
              id={preData.id}
              onChange={this.changeDetailView}
              classNameNode='detail-text'
            />
          ) : (
            <div className="null" />
          )}
          <ImageMovieMap
            type='personAlarm'
            data={data}
            libType={libType}
            key={data && data.id}
            maptype={false}
          />
          {nextData ? (
            <PageDetails
              waterType="multiple"
              pageType="next"
              classNameNode='detail-text'
              imgUrl={nextData.faceUrl}
              id={nextData.id}
              onChange={this.changeDetailView}
            />
          ) : (
            <div className="null" />
          )}
        </div>
        <HorizontalScrollLayout
          size={6}
          data={alarmInfoList}
          className="private-horizont"
          renderItem={this.renderItem}
        />
      </>
    );
  }
  render() {
    let { loading } = this.state;
    return (
      <div className="private-net-detail">
        <Loading loading={loading}>{this.renderContent()}</Loading>
      </div>
    );
  }
}

export default PrivateNetDetail;
